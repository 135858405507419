export const LOGIN_START =  'loginStart';
export const LOGIN_SUCCESS = 'loginSuccess';
export const LOGIN_ERROR = 'loginError';
export const LOGIN_USER = 'loginUser';
export const LOGIN_CONTRACTS = 'loginContracts';
export const REFRESH_START =  'refreshStart';
export const REFRESH_SUCCESS = 'refreshSuccess';
export const TOKEN_SUCCESS = 'tokenSuccess';
export const REFRESH_ERROR = 'refreshError';
export const LOGOUT_START = 'logoutStart';
export const LOGOUT_SUCCESS = 'logoutSuccess';
export const LOGOUT_ERROR = 'logoutError';
export const CONTRACTS_START = 'contractsStart';
export const CONTRACTS_SUCCESS = 'contractsSuccess';
export const CONTRACTS_ERROR = 'contractsError';
