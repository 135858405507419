const interval = 40;
export default class {
    constructor(name, timeout = 10000, seed) {
        this.name = name;
        this.timeout = timeout;
        this.seed = seed;
        if (!this.seed) {
            this.seed = Math.random();
        }
        this.heartbeat = 0;
        this.expires = 0;
    }
    
    /**
     * попытка выставить замок.
     * 
     * либо замок закроется,
     * либо подождет пока откроется
     */
    lock() {
        return new Promise((res) => {

            // вызывается при изменении localstorage
            this.lockCheck = () => {
                const doublecheck = this.read();

                // если замок существует и не протух
                if (doublecheck && doublecheck.expires > Date.now()) {
                    return;
                }
                if (this.unlock()) {
                    res();
                }
            };
            const existing = this.read();
            if (!existing || existing.expires < Date.now()) {
                this.write();

                // еще раз проверяем после интервала,
                // что замок выставлен

                setTimeout(() => {
                    const doublecheck = this.read();
                    let claimed = false;
                    if (doublecheck) {
                        if (doublecheck.seed === this.seed || doublecheck.expires < Date.now()) {
                            claimed = true;

                            // вызываем функцию закрытия замка
                            this.claimLockFlow(res);
                            return;
                        }
                    }
                    if (claimed) {
                        return;
                    }
                    // вызываем функцию ожидания открытия замка
                    this.waitOnLockFlow();
                }, interval);
                return;
            }
            this.waitOnLockFlow();
        });
    }

    /**
     * открываем замок
     */

    unlock() {
        let lock = this.read();

        //открываеть только если замок выставлен или протух
        if (lock && lock.seed !== this.seed && lock.expires > Date.now()) {
            return false;
        }
        clearInterval(this.heartbeat);
        window.removeEventListener("storage", this.lockCheck);
        localStorage.removeItem(this.name);
        return true;
    }

    read() {
        const res = localStorage.getItem(this.name);
        if (!res) {
            return undefined;
        }
        try {
            return JSON.parse(res);
        }
        catch (e) {
            return undefined;
        }
    }

    /**
     * записать в localstorage текущий объект
     */
     write() {
        localStorage.setItem(this.name, JSON.stringify(this));
    }

    /**
     * обновить время истечения замка
     */
    setExpiration() {
        this.expires = Date.now() + this.timeout;
    }

    /**
     * закрываем замок
     */
     claimLockFlow(res) {
        this.setExpiration();

        // замок был закрыт
        window.removeEventListener("storage", this.lockCheck);
        this.heartbeat = setInterval(() => {
            this.setExpiration();
            this.write();
        }, this.timeout - this.timeout / 2);
        res();
    }

    /**
     * ждем пока замок отркоется или протухнет
     */
    waitOnLockFlow() {
        window.addEventListener("storage", this.lockCheck);

        // в случае если другая вкладка закрылась раньше нужного времени
        this.heartbeat = setInterval(() => {
            this.lockCheck();
        }, this.timeout);
    }
}