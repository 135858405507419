import axios from 'axios';

export default class ApiAuth {
  constructor(url) {
    this.request = axios.create({
      baseURL: url,
      timeout: 25000,
      validateStatus() {
        return true;
      },
      withCredentials: true,
    });

    this.redirectUrl = '/';

    this.options = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };
  }

  login(data, _callback) {
    const result = `${data.email}:${data.password}`;
    delete data.email;
    delete data.password;
    const options = JSON.parse(JSON.stringify(this.options));
    options.headers['Authorization'] = `Basic ${btoa(result)}`;

    return this.request.post('/login', data, options).then(_callback);
  }

  refresh(data, _callback) {
    return this.request.post('/refresh', data, this.options).then(_callback);
  }

  switchContract(data, _callback) {
    return this.request.post('/switchContract', data, this.options).then(_callback);
  }

  getContracts(data, _callback) {
    return this.request.post('/contracts', data, this.options).then(_callback);
  }

  logout(data, _callback) {
    return this.request.post('/logout', data, this.options).then(_callback);
  }
}
