import Vue from 'vue';
import * as mutations from './mutations.types';

export default {
  [mutations.LOGIN_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "refreshError", null);
  },
  [mutations.LOGIN_SUCCESS]: (state, user) => {
    Vue.set(state, "model", user);
    Vue.set(state, "loading", false);
  },
  [mutations.LOGIN_CONTRACTS]: (state, contracts) => {
    Vue.set(state, "contracts", contracts);
    Vue.set(state, "loading", false);
  },
  [mutations.LOGIN_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
  [mutations.REFRESH_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "refreshError", null);
  },
  [mutations.TOKEN_SUCCESS]: (state, token) => {
    Vue.set(state, "token", token);
  },
  [mutations.REFRESH_SUCCESS]: (state, user) => {
    Vue.set(state, "model", {...user, roles: state.model?.roles || [] });
    Vue.set(state, "loading", false);
    Vue.set(state, "refreshError", null);
  },
  [mutations.REFRESH_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "token", null);
    Vue.set(state, "refreshError", error);
  },
  [mutations.LOGOUT_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
    Vue.set(state, "token", null);
    Vue.set(state, "model", {});
    Vue.set(state, "contracts", null);
  },
  [mutations.LOGOUT_SUCCESS]: (state) => {
    Vue.set(state, "loading", false);
  },
  [mutations.LOGOUT_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
  [mutations.CONTRACTS_START]: (state) => {
    Vue.set(state, "loading", true);
    Vue.set(state, "error", null);
  },
  [mutations.CONTRACTS_SUCCESS]: (state) => {
    Vue.set(state, "loading", false);
  },
  [mutations.CONTRACTS_ERROR]: (state, error) => {
    Vue.set(state, "loading", false);
    Vue.set(state, "error", error);
  },
};
