import StorageLock from './lock';

export default class AuthInterceptor {
  constructor (store) {
    this.store = store;
    this.refreshTokenRequest = null;
  }

  async intercept() {
    let token = this.store.getters.token;

    const tokenExp = this.store.getters.exp;
    const now = new Date().getTime();
    const isTokenExpired = now >= tokenExp;

    if (!token || isTokenExpired) {
      const sl = new StorageLock('isRefreshing');
      await sl.lock();

      if (this.refreshTokenRequest === null) {
        this.refreshTokenRequest = this.store.dispatch('refreshAction');
      }

      token = await this.refreshTokenRequest;
      this.refreshTokenRequest = null;

      sl.unlock();
    }

    return token;
  }
}
